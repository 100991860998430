<template>
  <router-view></router-view>
</template>
<script>
import Vue from "vue";
import i18n from "./i18n";

export default {
  name: "App",
  mounted() {
    i18n.locale = this.$store.getters["lang/lang"];
  },
  created() {
    Vue.prototype.base64_encode = (i) => btoa(i);
    Vue.prototype.base64_decode = (i) => atob(i);
    let href = window.location.href;
    let host = window.location.hostname;
    if (host.includes("www.")) {
      host = host.replace("www.", "");
    }
    if (host != "") {
      localStorage.setItem("localelang", this.$store.state.lang.locale);
      if (host != "localhost" && host != "192.168.0.62") {
        this.$auth.setVendorSettings({
          order_web: host,
          is_langauge: this.$store.state.lang.locale,
          latitude: localStorage.getItem("latitude"),
          longitude: localStorage.getItem("longitude"),
        });
      } else {
        //this.$auth.setVendorSettings({order_web: 'miliskitchen.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'honest.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        this.$auth.setVendorSettings({
          order_web: "order.q-jump.de",
          is_langauge: this.$store.state.lang.locale,
        });
        //this.$auth.setVendorSettings({order_web: '15.206.158.127',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'ressto.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'order.hungryji.com',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        // this.$auth.setVendorSettings({order_web: 'www.gerechtaanhuis.nl',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'order.hungryji.com',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'hungryji.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        // this.$auth.setVendorSettings({order_web: 'pizza247.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'madeinhome.in',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'order.deliverze.com',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'freco.in',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'okdel.in',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'cravemeals.app',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'app.goodhalal.fr',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'v4proteins.com',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'pizzaking.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'quickwind.in',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'trolleymate.co.za',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'order.northerneats.co.za',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'grocery.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'bnbdeliveries.com',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'wedun.in',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'foodkart.net',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'sta.stabexinternational.com',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'cookants.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'enzofood.com',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'pakapepe.com',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'grocery.deonde.co',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'platers.de',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'dingernow.com',is_langauge: this.$store.state.lang.local  e,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'easybuy.ng',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});
        //this.$auth.setVendorSettings({order_web: 'goordersa.com',is_langauge: this.$store.state.lang.locale});
        //this.$auth.setVendorSettings({order_web: 'food.deonde.co',is_langauge: this.$store.state.lang.locale});
        //this.$auth.setVendorSettings({order_web: 'sowoos.com',is_langauge: this.$store.state.lang.locale});
        //this.$auth.setVendorSettings({order_web: 'pocketsaver.deonde.co',is_langauge: this.$store.state.lang.locale});
      }

      if (host == "quickwind.in") {
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://quickwind.in/js/quickwind_fb.js");
        document.head.appendChild(scriptEle);
        let noscriptEle = document.createElement("noscript");
        let imgelemnt = document.createElement("img");
        imgelemnt.height = "1";
        imgelemnt.width = "1";
        imgelemnt.src =
          "https://www.facebook.com/tr?id=550716509834392&ev=PageView&noscript=1";
        imgelemnt.style.display = "none";
        noscriptEle.appendChild(imgelemnt);
        document.head.appendChild(noscriptEle);

        let metatag = document.createElement("meta");
        metatag.setAttribute("name", "facebook-domain-verification");
        metatag.setAttribute("content", "ed1b7iuu7411sh5gyq9y2ha8epz2b8");
        document.head.appendChild(metatag);
      }
      if (host == "enzofood.com") {
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://quickwind.in/js/enzofood_fb.js");
        document.head.appendChild(scriptEle);

        let noscriptEle = document.createElement("noscript");
        let imgelemnt = document.createElement("img");
        imgelemnt.height = "1";
        imgelemnt.width = "1";
        imgelemnt.src =
          "https://www.facebook.com/tr?id=356819635193871&ev=PageView&noscript=1";
        imgelemnt.style.display = "none";
        noscriptEle.appendChild(imgelemnt);
        document.head.appendChild(noscriptEle);
      }
    }

    this.$auth.setUser((type) => {
      if (type) {
        let currentRoute = href.substring(href.lastIndexOf("/") + 1);
        if (currentRoute == "sign-in") {
          this.$router.push({ name: type }).catch(() => {});
        }
      }
    });
  },

  watch: {
    "$auth.setting": function (preval, newval) {
      if ((preval == null || preval.length == 0) && this.$auth.init) {
        this.$router.push({ name: "notfound" }).catch(() => {});
      }
    },
  },
};
</script>
